import PortfolioLayout from "./PortfolioLayout";
import SisImage from "./Images/SisImage";
import SisImage3d from "./Images/SisImage3d";


function PortfolioSisDandify() {
    return (
        <div id={"portfolio-sis-dandify"}>
            <PortfolioLayout
                background={"white"}
                backgroundText={"text-neutral-500"}
                textColor={"text-black"}
                hoverTextColor={"hover:text-white"}
                hoverColor={"hover:bg-black"}
                borderColor={"border-black"}
                title={"SIS DANDIFY"}
                link={"https://sisdandify.com/"}
                image={SisImage}
                image3d={SisImage3d}
                description={"An app for a luxury boutique"}
                flexReverse={"md:flex-row"}/>
        </div>
    )
}

export default PortfolioSisDandify;