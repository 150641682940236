import {FC, memo} from 'react';

import Icon, {IconProps} from './Icon';

const EmailIcon: FC<IconProps> = memo(props => (
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.785 18">
            <g id="_8666723_mail_icon" data-name="8666723_mail_icon" transform="translate(-0.607 -3)">
                <path id="Path_14" data-name="Path 14" d="M4,4H20a2.006,2.006,0,0,1,2,2V18a2.006,2.006,0,0,1-2,2H4a2.006,2.006,0,0,1-2-2V6A2.006,2.006,0,0,1,4,4Z" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                <path id="Path_15" data-name="Path 15" d="M22,6,12,13,2,6" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            </g>
        </svg>
    </Icon>

));

export default EmailIcon;
