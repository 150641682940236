import classNames from 'classnames';
import {FC, memo, useCallback, useEffect, useRef, useState} from 'react';

import {testimonials} from '../../data/Data';
import useInterval from '../Hooks/useInterval';
import useWindow from '../Hooks/useWindow';
import {Testimonial} from "../../data/Data";

const Testimonials: FC = memo(() => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [scrollValue, setScrollValue] = useState(0);

    const itemWidth = useRef(0);
    const scrollContainer = useRef(null);

    const {width} = useWindow();

    useEffect(() => {
        itemWidth.current = scrollContainer.current ? scrollContainer.current.offsetWidth : 0;
    }, [width]);

    useEffect(() => {
        if (scrollContainer.current) {
            const newIndex = Math.round(scrollContainer.current.scrollLeft / itemWidth.current);
            setActiveIndex(newIndex);
        }
    }, [itemWidth, scrollValue]);

    const setTestimonial = useCallback(
        (index: number) => () => {
            if (scrollContainer !== null && scrollContainer.current !== null) {
                scrollContainer.current.scrollLeft = itemWidth.current * index;
            }
        },
        [],
    );
    const next = useCallback(() => {
        if (activeIndex + 1 === testimonials.length) {
            setTestimonial(0)();
        } else {
            setTestimonial(activeIndex + 1)();
        }
    }, [activeIndex, setTestimonial]);

    const handleScroll = useCallback(event => {
        setScrollValue(event.currentTarget.scrollLeft);
    }, []);

    useInterval(next, 20000);

    return (
            <div id={'testimonials'}
                className={'flex flex-col h-screen snap-start w-full justify-start bg-cover bg-center px-4 py-16 md:py-24 lg:px-8 bg-white overflow-hidden'}>
                <span className={"text-black font-display text-center pt-6 pb-6 md:pt-20 text-5xl"}>TESTIMONIALS</span>
                <div className="z-10 w-full max-w-screen-md px-4 max-h-24 mx-auto lg:px-0">
                    <div className="h-[30rem] flex flex-col items-center gap-y-6 rounded-xl bg-white p-6 shadow-lg sm:h-[20rem]">
                        <div
                            className="no-scrollbar overflow-y-scroll flex w-full touch-pan-x snap-x snap-mandatory gap-x-6 overflow-x-auto scroll-smooth"
                            onScroll={handleScroll}
                            ref={scrollContainer}>
                            {testimonials.map((testimonial, index) => {
                                const isActive = index === activeIndex;
                                return (
                                    <TestimonialItem isActive={isActive} key={`${testimonial.name}-${index}`} testimonial={testimonial} />
                                );
                            })}
                        </div>
                        <div className="flex gap-x-4">
                            {[...Array(testimonials.length)].map((_, index) => {
                                const isActive = index === activeIndex;
                                return (
                                    <button
                                        className={`h-3 w-3 rounded-full bg-gray-300 transition-all duration-500 sm:h-4 sm:w-4 ${isActive ? 'scale-100 opacity-100' : "scale-75 opacity-60"}`}
                                        disabled={isActive}
                                        key={`select-button-${index}`}
                                        onClick={setTestimonial(index)}></button>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
    );
});

const TestimonialItem: FC<{testimonial: Testimonial; isActive: boolean}> = memo(
    ({testimonial: {text, name, image}, isActive}) => (
        <div
            className={classNames(
                'flex w-full shrink-0 snap-start snap-always flex-col items-start gap-y-4 p-2 transition-opacity duration-1000 sm:flex-row sm:gap-x-6 pt-8',
                isActive ? 'opacity-100' : 'opacity-0',
            )}>
            <div className="relative h-14 w-14 shrink-0 sm:h-16 sm:w-16">
                <img className="h-full w-full rounded-full" src={image}  alt={"avatar"}/>
            </div>
            <div className="overflow-y-scroll flex flex-col gap-y-4">
                <p className="prose prose-sm font-medium italic text-black sm:prose-base">{text}</p>
                <p className="text-xs text-right italic text-black sm:text-sm md:text-base lg:text-lg">{name}</p>
            </div>
        </div>
    ),
);

export default Testimonials;