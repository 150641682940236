function CarouselEducationItem(props) {

    return (
        <div className={`w-60 h-24 rounded-3xl shadow-md p-3 md:w-96 md:h-28`}>
            <div className={"flex flex-row pt-3"}>
                <img src={props.logo} className={"w-12 h-12 mr-2 -z-10 md:mr-3"}
                     alt={"experience_logo"}/>
                <div>
                    <div className={"flex flex-col"}>
                        <span className={"font-bold text-xs md:text-base"}>{props.title}</span>
                        <span className={"text-sm md:text-base"}>{props.subtitle}</span>
                        <span
                            className={"text-sm text-neutral-400 md:text-base"}>{props.period}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CarouselEducationItem;