import {useEffect} from "react";

function useOutsideClick(ref, collapsed, func) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target) && !collapsed) {
                func()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, collapsed, func]);
}

export default useOutsideClick;