import portrait from "../assets/images/portrait-circle.png";
import React from "react";
import ResumeContacts from "./ResumeContacts";
import ResumeSkills from "./ResumeSkills";
import DownloadIcon from "./Icons/DownloadIcon";
import {myName, resumeSkills, roles} from "../data/Data";
import {Link} from "react-router-dom";

function ResumeStickyNavbar() {

    return (
        <div
            className={"sticky h-screen2/5 z-30 top-0 bg-neutral-900 rounded-b-3xl shadow-xl px-3 md:h-screen md:w-1/4 md:rounded-none md:shadow-none overflow-scroll"}>
            <div className={"h-3/5 pt-12 min-h-[120px] flex justify-center md:h-1/5 md:px-5"}>
                <img src={portrait} alt={'portrait'} className={"object-contain h-full rounded-full border-neutral-900 border-2"}/>
            </div>
            <div className={"w-full flex flex-col justify-start items-center text-center pt-3 px-4"}>
                <span className={"text-white font-bold text-md px-2 pb-4 lg:text-2xl"}>{myName}</span>
                <div className={"w-1/4 h-[0.04rem] bg-neutral-600"}></div>
                <span
                    className={"text-neutral-500 font-light text-sm px-2 py-3 md:py-3 lg:text-lg"}>{roles}</span>
                <div className={"hidden md:flex flex-col items-start"}>
                    <ResumeContacts textSize={"text-[1vw]"}/>
                    <ResumeSkills skills={resumeSkills}/>
                    <div className={"w-full items-end py-5"}>
                        <Link to="/resume"
                            className={"w-4/5 h-10 border-2 border-red-600 rounded-full flex flex-col justify-center m-auto cursor-pointer hover:bg-red-600"}>
                            <div className={"flex flex-row items-center m-auto"}>
                                <span className={"text-white font-light pr-3 text-sm"}>Download</span>
                                <DownloadIcon className={"h-4 w-4 fill-white"}/>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResumeStickyNavbar;