import PortfolioValentinaTioli from "../PortfolioValentinaTioli";
import PortfolioSisDandify from "../PortfolioSisDandify";
import React from "react";
import PortfolioHeader from "../PortfolioHeader";

function Resume() {
    return (
        <div id={"portfolio"} className={"font-display cursor-default"}>
            <PortfolioHeader/>
            <PortfolioSisDandify/>
            <PortfolioValentinaTioli/>
        </div>
    )
}

export default Resume;