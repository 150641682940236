import {education} from "../data/Data";
import React from "react";
import Carousel from "./Carousel";
import CarouselEducationItem from "./CarouselEducationItem";

function ResumeEducation() {

    return (
        <Carousel items={education} height={"h-36"} carouselItem={CarouselEducationItem}/>
    )
}

export default ResumeEducation;