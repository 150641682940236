import React from "react";
import ResumeSkillItem from "./ResumeSkillItem";

function ResumeSkills(props) {

    return (
        <div className={"w-full flex flex-col items-start py-4"}>
            <div className={"w-full text-white flex flex-col text-left"}>
                <span className={"font-semibold text-xl"}>SKILLS</span>
                {props.skills.map(({title, level}) => (
                        <ResumeSkillItem key={title} title={title} level={level}/>
                    )
                )}
            </div>
        </div>
    )
}

export default ResumeSkills;