import React, {useState} from 'react';
import './index.css';
import Header from "./components/Header";
import Home from "./components/Sections/Home";
import Portfolio from "./components/Sections/Portfolio";
import Resume from "./components/Sections/Resume";
import Sidebar from "./components/MySidebar";
import Contact from "./components/Sections/Contact";
import portrait from "./assets/images/portrait-circle.png"
import {FloatingWhatsApp} from "react-floating-whatsapp";
import {HashRouter, Route, Routes} from "react-router-dom";
import Testimonials from "./components/Sections/Testimonials";
import ResumeDownload from "./components/ResumeDownload";

function App() {
    const [collapseSidebar, setCollapseSidebar] = useState(true);

    function toggleSidebar() {
        setCollapseSidebar(!collapseSidebar)
    }

    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={
                    <div className={"h-screen flex overflow-x-hidden"}>
                        <Sidebar collapsed={collapseSidebar} toggleSidebar={toggleSidebar}/>
                        <div id={"sections"} className={"h-screen min-w-[100vw] overflow-auto snap-y snap-mandatory"}>
                            <Header collapsed={collapseSidebar} toggleSidebar={toggleSidebar}/>
                            <Home/>
                            <Portfolio/>
                            <Testimonials/>
                            <Contact/>
                            <FloatingWhatsApp
                                phoneNumber={393387292808}
                                accountName={"Gaspare Mascolino"}
                                avatar={portrait}
                                chatMessage={"Hi there 👋🏻,\n" +
                                    "\n" +
                                    "How can I help?"}
                            />
                        </div>
                    </div>
                }>
                </Route>
                <Route path="resume" element={<Resume/>}/>
                <Route path="resume/download" element={<ResumeDownload/>}/>
            </Routes>
        </HashRouter>
    );
}


export default App;