import React from "react";
import {scrollToSection, sections} from "../data/Data";


function Header(props) {

    return (
        <nav className={"fixed flex text-sm z-40 text-white mix-blend-difference w-screen p-6 md:justify-end"}>
            {<div id={"burger"} className={"md:hidden cursor-pointer"}>
                <div className={"flex flex-col text-md font-bold leading-[0.6]"} onClick={props.toggleSidebar}>
                    <span className={""}>___</span>
                    <span className={""}>___</span>
                    <span className={""}>___</span>
                </div>
            </div>
            }
            <div id={"headers"} className={"hidden md:flex"}>
                {sections.map(section =>
                    <div key={section}
                         className={"font-body mr-6 cursor-pointer"}
                         onClick={() => scrollToSection(section)}>
                        <span className={"capitalize"}>{section}</span>
                    </div>
                )}
            </div>
        </nav>
    )
}

export default Header;