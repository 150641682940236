import {useEffect, useState} from "react";

function ResumeSkillItem(props) {
    const [transition, setTransition] = useState("-translate-x-[100%]");
    const bars = 4;

    useEffect(() => setTransition(props.level), [props.level]);

    return (
        <div>
            {props.level ?
                <div className={"py-2"}>
                    <span className={"text-sm"}>{props.title}</span>
                    <div className={"relative my-1 h-3 bg-neutral-400 rounded-full overflow-hidden"}>
                        <div
                            className={`absolute h-full bg-red-600 w-full transition ease-out duration-700 ${transition}`}></div>
                        <div className={"absolute w-full h-full flex justify-start"}>
                            <div className={"w-1/5"}></div>
                            {[...Array(bars)].map((e, i) =>
                                <div key={i} className={"w-1/5 flex"}>
                                    <div className={"w-0.5 bg-neutral-800"}></div>
                                </div>
                            )}
                        </div>
                    </div>
                </div> :
                <div className={"pt-5"}>
                    <span className={"text-sm font-bold"}>{props.title}</span>
                </div>
            }
        </div>
    )

}

export default ResumeSkillItem;