import PortfolioLayout from "./PortfolioLayout";
import ValentinaTioliImage from "./Images/ValentinaTioliImage";
import ValentinaTioliImage3d from "./Images/ValentinaTioliImage3d";


function PortfolioValentinaTioli() {
    return (
        <div id={"portfolio-valentina-tioli"}>
            <PortfolioLayout
                background={"bg-gradient-to-r from-[#000000] to-[#000010]"}
                backgroundText={"text-white"}
                textColor={"text-white"}
                hoverTextColor={"hover:text-black"}
                hoverColor={"hover:bg-white"}
                borderColor={"border-white"}
                difference={"mix-blend-difference"}
                title={"VALENTINA TIOLI"}
                link={"https://valentinatioli.com/"}
                image={ValentinaTioliImage}
                image3d={ValentinaTioliImage3d}
                description={"A website for a wonderful artist"}
                flexReverse={"md:flex-row-reverse"}/>
        </div>
    )
}

export default PortfolioValentinaTioli;