import React from "react";
import {resumeSections} from "../data/Data";

function ResumeLayout() {

    return (
        <div className={'snap-end md:grid md:grid-cols-4'}>
            <div className={"h-screen2/5 md:h-screen"}></div>
            <div className={"overflow-scroll h-screen3/5 md:h-screen md:col-span-3 md:grid"}>
                <div className={"hidden md:flex sticky z-20 top-0 w-full bg-white shadow h-20"}></div>
                <div className={"z-10 pt-6 px-8 sm:px-16 text-center mb:mt-10 md:pb-20 md:text-left"}>
                    {resumeSections.map(({title, component}) => (
                            <div key={title} className={"resume-section"}>
                                <span className={"resume-section-title"}>{title}</span>
                                <div className={"resume-section-body"}>{React.createElement(component)}</div>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    )
}

export default ResumeLayout;