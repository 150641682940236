import ResumeStickyNavbar from "../ResumeStickyNavbar";
import ResumeLayout from "../ResumeLayout";

function Resume() {
    return (
        <div id={"resume"} className={"font-body cursor-default"}>
            <ResumeStickyNavbar/>
            <ResumeLayout/>
        </div>
    )
}

export default Resume;