import {FC, memo} from 'react';

import Icon, {IconProps} from './Icon';

const EmailIcon: FC<IconProps> = memo(props => (
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.89 21.93">
            <path id="_8666632_phone_icon" data-name="8666632_phone_icon"
                  d="M22,16.92v3a2,2,0,0,1-2.18,2,19.79,19.79,0,0,1-8.63-3.07,19.5,19.5,0,0,1-6-6A19.79,19.79,0,0,1,2.12,4.18,2,2,0,0,1,4.11,2h3a2,2,0,0,1,2,1.72,12.84,12.84,0,0,0,.7,2.81,2,2,0,0,1-.45,2.11L8.09,9.91a16,16,0,0,0,6,6l1.27-1.27a2,2,0,0,1,2.11-.45,12.84,12.84,0,0,0,2.81.7A2,2,0,0,1,22,16.92Z"
                  transform="translate(-1.111 -1)" fill="none" stroke="#fff" strokeLinecap="round"
                  strokeLinejoin="round" strokeWidth="2"/>
        </svg>
    </Icon>

));

export default EmailIcon;
