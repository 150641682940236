import {FC, memo} from 'react';

import {socialLinks} from '../data/Data';

const Socials: FC = memo(() => {
    return (
        <>
            {socialLinks.map(({label, Icon, href}) => (
                <a
                    aria-label={label}
                    className="-m-1.5 text-black rounded-md p-1.5 sm:-m-3 sm:p-3"
                    href={href}
                    target={"_blank"}
                    key={label}
                    rel={"noreferrer"}>
                    <Icon className="h-6 w-6 lg:h-7 lg:w-7 align-baseline"/>
                </a>
            ))}
        </>
    );
});

Socials.displayName = 'Socials';
export default Socials;
