import React, {useEffect, useState} from "react";

function CarouselItem(props) {

    const [opacity, setOpacity] = useState("opacity-0");

    useEffect(() => setOpacity("opacity-100"), []);

    return (
        <div className={`carousel-item px-3 ease-in transition ${props.duration} ${opacity}`}>
            {React.createElement(props.item, props)}
        </div>
    )
}

export default CarouselItem;