import {FC, memo} from 'react';

import Icon, {IconProps} from './Icon';

const MapIcon: FC<IconProps> = memo(props => (
    <Icon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 22">
            <g id="_8666591_map_icon" data-name="8666591_map_icon" transform="translate(0 -1)">
                <path id="Path_13" data-name="Path 13" d="M1,6V22l7-4,8,4,7-4V2L16,6,8,2Z" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                <line id="Line_6" data-name="Line 6" y2="16" transform="translate(8 2)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                <line id="Line_7" data-name="Line 7" y2="16" transform="translate(16 6)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            </g>
        </svg>

    </Icon>

));

export default MapIcon;
