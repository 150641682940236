import CarouselItem from "./CarouselItem";
import React from "react";

function Carousel(props) {

    return (
        <div className={`relative ${props.height}`}>
            <div
                className={`carousel absolute -translate-x-16 left-0 flex items-center w-[calc(100%+6rem)] sm:w-[calc(100%+8rem)] ${props.height}`}>
                <div className={"carousel-item w-16"}></div>
                {props.items.map(({logo, title, subtitle, period, description}, i) => (
                        <CarouselItem
                            key={i}
                            logo={logo}
                            title={title}
                            subtitle={subtitle}
                            period={period}
                            description={description}
                            item={props.carouselItem}
                            duration={"duration-" + (300 + (i * 200))}/>
                    )
                )}
            </div>
        </div>
    )
}

export default Carousel;