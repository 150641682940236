import {profileDescription} from "../data/Data";

function ResumeProfile() {

    return (
        <pre className={"font-body whitespace-pre-wrap"}>
            {profileDescription}
        </pre>
    )
}

export default ResumeProfile;