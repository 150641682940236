import React, {useRef} from "react";
import {Menu, MenuItem, Sidebar} from "react-pro-sidebar";
import {scrollToSection, sections} from "../data/Data";
import useOutsideClick from "./Hooks/useOutsideClick";

function MySidebar(props) {
    const sidebarShadow = 'sidebar-shadow'
    const width = 'w-0 opacity-0'
    const sidebarRef = useRef(null);
    useOutsideClick(sidebarRef, props.collapsed, props.toggleSidebar);

    return (
        <div id={"sidebar"} ref={sidebarRef} className={`${!props.collapsed ? sidebarShadow : ''} ${props.collapsed ? width : ''} md:hidden`}>
            <Sidebar collapsedWidth={"0"} width={"200px"} collapsed={props.collapsed} backgroundColor={"rgb(255, 255, 255, 1)"}>
                <Menu>
                    <div className={"flex flex-row font-bold font-display pt-10 pb-5 pl-4"}>
                        <h2>GASPARE</h2>
                        <h2 className={"text-red-600"}>MASCOLINO</h2>
                    </div>
                    {sections.map((section) => {
                        return (
                            <MenuItem key={section}>
                                <div
                                    className={"font-body mr-6 cursor-pointer"}
                                    onClick={() => {
                                        scrollToSection(section)
                                        props.toggleSidebar()
                                    }

                                }>
                                    <span className={"capitalize"}>{section}</span>
                                </div>
                            </MenuItem>
                        )
                    })}
                </Menu>
            </Sidebar>
        </div>
    )
}

export default MySidebar;