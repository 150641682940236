import {resumeContacts} from "../data/Data";
import React from "react";

function ResumeContacts(props) {

    return (
        <div className={"py-6 flex flex-col"}>
            {resumeContacts.map(({label, Icon, href, value}) => {
                if(label !== 'Phone' || props.showPhone)
                    return <div key={label} className={"inline-flex items-center py-2 cursor-pointer"}>
                               <Icon className="h-4 w-4 align-baseline"></Icon>
                               <a className={`text-white ${props.textSize} px-3 hover:text-neutral-200 underline-offset-4 lg:text-xs`}
                                  href={href} target="_blank" rel="noreferrer">{value}</a>
                    </div>
                return <div key={label}></div>
                })
        }
        </div>
    )
}

export default ResumeContacts;