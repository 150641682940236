import {certifications} from "../data/Data";
import React from "react";
import Carousel from "./Carousel";
import CarouselCertificationItem from "./CarouselCertificationItem";

function ResumeCertifications() {

    return (
        <>
            <Carousel items={certifications} height={"h-36"} carouselItem={CarouselCertificationItem}/>
            <div className={"w-full h-[15rem]"}></div>
        </>

    )
}

export default ResumeCertifications;