import React, {FC} from "react"
import type {IconProps} from "../components/Icons/Icon";
import InstagramIcon from "../components/Icons/InstagramIcon";
import LinkedInIcon from "../components/Icons/LinkedInIcon";
import GithubIcon from "../components/Icons/GithubIcon";
import ResumeProfile from "../components/ResumeProfile";
import ResumeExperience from "../components/ResumeExperience";
import EmailIcon from "../components/Icons/EmailIcon";
import PhoneIcon from "../components/Icons/PhonelIcon";
import MapIcon from "../components/Icons/MapIcon";
import unicreditLogo from "../assets/images/resume/unicredit.jpeg";
import altenLogo from "../assets/images/resume/alten.jpeg";
import ResumeEducation from "../components/ResumeEducation";
import polimiLogo from "../assets/images/resume/polimi.jpeg";
// import iuLogo from "../assets/images/resume/iu.jpg";
import ResumeCertifications from "../components/ResumeCertifications";
import reactLogo from "../assets/images/resume/react.png";
import mongoDbLogo from "../assets/images/resume/mongodb.png";
import angularLogo from "../assets/images/resume/angular.png";
import valentinatioli from "../assets/images/portfolio/valentinatioli.jpg"
import sisdandify from "../assets/images/portfolio/sis.jpg"
export const sections = ['home', 'portfolio', 'testimonials', 'contact', 'resume'];


export const myName = 'Gaspare Mascolino'

export interface Social {
    label: string;
    Icon: FC<IconProps>;
    href: string;
}

export const socialLinks: Social[] = [
    {label: 'LinkedIn', Icon: LinkedInIcon, href: 'https://www.linkedin.com/in/gaspare-mascolino-a518a0172/'},
    {label: 'Github', Icon: GithubIcon, href: 'https://github.com/gasparemascolino'},
    {label: 'Instagram', Icon: InstagramIcon, href: 'https://www.instagram.com/gasparewebdev/'},
];

export interface ResumeSection {
    title: string;
    component: React.FunctionComponent;
}

export const roles = 'SOFTWARE ENGINEER'

export const resumeSections: ResumeSection[] = [
    {title: 'PROFILE', component: ResumeProfile},
    {title: 'EXPERIENCE', component: ResumeExperience},
    {title: 'EDUCATION', component: ResumeEducation},
    {title: 'CERTIFICATES', component: ResumeCertifications},
]

export const profileDescription = "My philosophy is to write elegant, clean, and efficient code, creating high-quality software solutions, and well-structured algorithms that are easily maintainable over the long term. Adhering to the KISS (Keep It Simple, Stupid) principle, I prioritize simplicity and readability in my code to ensure maintainability for myself and my team. I am not afraid to say \"no\" when a project strays from its ultimate goal of delivering value efficiently. " +
                                  "This passion drove my studies at Politecnico di Milano, where I deepened my knowledge of programming languages, algorithms, data structures, and design models. There, I developed my first projects, including optimization algorithms and a videogame in Java." +
                                  "\n\n" +
                                  "With multiple years of professional software development experience, I bring the same enthusiasm to my work, always eager to learn, solve, and create more. I thrive on challenges and quickly learn new technologies and methodologies. I am committed to helping and mentoring my colleagues and look forward to the continuous growth and excitement that software development offers. " +
                                  "In my free time, I continue to pursue my passion for software development by creating applications, websites and e-commerce solutions for private clients (see my website www.gasparemascolino.com)."

export interface Contact {
    label: string;
    Icon: FC<IconProps>;
    href: string;
    value: string;
}

export const resumeContacts: Contact[] = [
    {
        label: 'Email',
        Icon: EmailIcon,
        href: 'mailto:gaspare.mascolino@icloud.com',
        value: 'gaspare.mascolino@icloud.com'
    },
    {
        label: 'Phone',
        Icon: PhoneIcon,
        href: 'https://wa.me/393911102750',
        value: '+39 3911102750'
    },
    {
        label: 'Location',
        Icon: MapIcon,
        href: 'https://www.google.com/maps/d/embed?mid=1lVs8WsfQlrW0xu_lXkGAC6mgeWY&hl=en_US&ehbc=2E312F',
        value: 'Milan, Italy'
    },
];

export interface Skill {
    title: string;
    level: string;
}

export const resumeSkills: Skill[] = [
    {title: 'Java', level: '-translate-x-[0%]'},
    {title: 'Python', level: '-translate-x-[20%]'},
    {title: 'React', level: '-translate-x-[20%]'},
]

export const resumeDownloadSkills: Skill[] = [
    {title: 'Backend Development', level: ''},
    {title: 'Java', level: '-translate-x-[0%]'},
    {title: 'Python', level: '-translate-x-[20%]'},
    {title: 'Groovy', level: '-translate-x-[20%]'},
    {title: 'Frontend Development', level: ''},
    {title: 'Angular', level: '-translate-x-[0%]'},
    {title: 'React', level: '-translate-x-[20%]'},
    {title: 'Languages', level: ''},
    {title: 'English', level: '-translate-x-[20%]'},
    {title: 'Spanish', level: '-translate-x-[40%]'},

]

export const languages: Skill[] = [
    {title: 'Italian', level: ''},
    {title: 'English', level: '-translate-x-[20%]'},
    {title: 'Spanish', level: '-translate-x-[40%]'},
]

export interface CarouselItem {
    logo: string;
    title: string;
    subtitle: string;
    period: string;
    description: string;
    skills: string[];
}

export const experiences: CarouselItem[] = [
    {
        logo: unicreditLogo,
        title: 'Software Engineer',
        subtitle: 'UniCredit',
        period: 'Apr 2023 - present',
        // eslint-disable-next-line no-useless-concat
        description: 'In Anti Financial Crime design, development and maintenance of management and control systems for the prevention, identification and mitigation of illicit financial activities.\n' +
                     'Develop robust software solutions to monitor transactions, analyze data patterns, and ensure compliance with regulatory requirements.',
        skills: [
            'Design, develop, and maintain RESTful APIs using Spring Boot (Java) and Django/Flask (Python).',
            'Integrate and manage databases using PostgreSQL and MongoDB',
            'Ensure secure communication between services, employing JWT for authentication and authorization.',
            'Develop and maintain a dynamic user interface using React, implement state management using Context API or Redux and integrate frontend components with backend services, ensuring seamless user experiences.'
        ]
    },
    {
        logo: unicreditLogo,
        title: 'Devops Engineer',
        subtitle: 'UniCredit',
        period: 'Jun 2021 - Apr 2023',
        description: 'I develop and maintain a shared library to streamline development processes. I integrate it into CI/CD pipelines, enforce code quality checks, unit and integration tests and automate deployments. ' +
                     'I also provide support and training to empower team members in utilizing the ' +
                     'shared library effectively, continuously seeking feedback.',
        skills: [
            'Integration with Jenkins automates build, test, and deployment processes, ensuring seamless CI/CD.',
            'Developing and managing Docker containers for applications.',
            'Orchestrating containerized deployments using Kubernetes, and utilizing Helm for managing Kubernetes applications on Google Cloud with GKE.',
        ]
    },
    {
        logo: altenLogo,
        title: 'Software Engineer',
        subtitle: 'Alten',
        period: 'Feb 2021 - Jun 2021',
        description: 'Design and development software components using Java and Typescript, along with frameworks like Angular, Node.js, and Spring. Version control with Git and artifact management with Nexus.',
        skills: [
            'Developing and maintaining web applications using Java, Spring, and Hibernate for backend logic, and Angular 7 for frontend interfaces.',
            'Implementing test automation using Postman and SoapUI for API testing and end-to-end (E2E) testing, ensuring robustness and reliability of the software.',
            'Leveraging Node.js and NestJS for building scalable and efficient server-side applications, integrating with Nexus artifact repository for managing dependencies.'
        ]
    }
]

export const education: CarouselItem[] = [
    {
        logo: polimiLogo,
        title: 'B.Sc Computer Science',
        subtitle: 'Politecnico di Milano',
        period: 'Sept 2017 - Sept 2020',
    },
]

export const certifications: CarouselItem[] = [
    {
        logo: reactLogo,
        title: 'React',
        subtitle: 'https://www.udemy.com/certificate/UC-a6e6d45e-f0e5-4006-9ae7-1b1335621b42/',
    },
    {
        logo: mongoDbLogo,
        title: 'MongoDB',
        subtitle: 'https://university.mongodb.com/course_completion/23ff92fe-a2da-42d9-9515-43ddd51bc7f4?utm_source=linkedin&utm_medium=social&utm_campaign=university_social_sharing',
    },
    {
        logo: angularLogo,
        title: 'Angular',
        subtitle: 'https://www.linkedin.com/posts/gaspare-mascolino-a518a0172_angular-certification-goalsetter-activity-6764987682956288000-18KL?utm_source=share&utm_medium=member_desktop',
    },
]

export function scrollToSection(section) {
    let toDivOffset = document.getElementById(section).offsetTop;

    if(section === 'resume') {
        toDivOffset += 1
    }

    document.getElementById('sections').scrollTo({
        top: toDivOffset,
        behavior: 'smooth'
    })
}

export interface Testimonial {
    image?: string;
    name: string;
    text: string;
}

export const testimonials = [
        {
            name: 'Valentina Tioli',
            text: 'I had the pleasure of working with Gaspare on my personal website, and I must say that his expertise and professionalism exceeded my expectations in every way. He followed me from the design part to the publication phase, satisfying all my need. I considered myself lucky to rely on him, highly recommended!',
            image: valentinatioli,
        },
        {
            name: 'SiS Dandify',
            text: 'Competence and professionalism, we are very grateful to you for having interpreted our needs very well... Even before and better than us. You guided us with patience and competence so that we made the right and appropriate choices for our business. After having previously consulted various companies, we opted for you who seemed to us to be the most reliable and, in hindsight, we can say that we chose the best. Thank you, Gaspare.',
            image: sisdandify,
        }
    ]

export const contacts: Contact[] = [
    {
        label: 'Email',
        Icon: EmailIcon,
        href: 'mailto:gaspare.mascolino@icloud.com',
        value: 'gaspare.mascolino@icloud.com'
    },
    {
        label: 'Phone',
        Icon: PhoneIcon,
        href: 'https://wa.me/393387292808',
        value: '+39 3387292808'
    },
    {
        label: 'Location',
        Icon: MapIcon,
        href: 'https://www.google.com/maps/d/embed?mid=1lVs8WsfQlrW0xu_lXkGAC6mgeWY&hl=en_US&ehbc=2E312F',
        value: 'Milan, Italy'
    },
    {
        label: 'LinkedIn',
        Icon: LinkedInIcon,
        href: 'https://www.linkedin.com/in/gaspare-mascolino-a518a0172/',
        value: 'Gaspare Mascolino'
    },
    {
        label: 'Github',
        Icon: GithubIcon,
        href: 'https://github.com/gasparemascolino',
        value: 'gasparemascolino'
    },
    {
        label: 'Instagram',
        Icon: InstagramIcon,
        href: 'https://www.instagram.com/gasparewebdev/',
        value: '@gasparewebdev'
    }
];